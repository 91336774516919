
import './NavBar.css'
import Logo1 from "../../assets/logosOficiales/logo_UTD-curvas-negativo2.png"
import Logo2 from "../../assets/logosOficiales/Min-Desarrollo-Productivo_color-sin-fondo.png"
import Logo3 from "../../assets/logosOficiales/LOGO_SVTT.png"
import Logo4 from "../../assets/logosOficiales/LOGO_UNSE.png"
import { Link } from 'react-router-dom'

const NavBar = (props) =>
{
	

	const handleOnClick  = (event) => {
		document.getElementById(event.target.name).scrollIntoView();
		props.onGoTo(event.target.name)
	}
	return (

		<nav className="navbar fixed-top shadow-lg navbar-expand-lg navbar-light bg-light px-2">
			<div className="container-fluid"  id="NavBar">
				<a className="navbar-brand" href="#">
					<img src={Logo2} alt=""  className=" d-inline-block align-text-top my-2 mx-3" id="logoMinisterio"/>

					<img src={Logo1} alt=""  className=" d-inline-block align-text-top my-2 mx-3" id="logoUTD"/>

					<img src={Logo3} alt=""  className=" d-inline-block align-text-top my-2 mx-3" id="logoUVT"/>

					<img src={Logo4} alt=""  className=" d-inline-block align-text-top my-2 mx-3" id="logoUNSE"/>
					
					</a>
				<button className="navbar-toggler ms-auto"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse  navbar-collapse" id="navbarTogglerDemo02">
				<ul className="navbar-nav gap-4 ms-auto me-4 mb-2 mb-lg-0">
					<li className="nav-item">
					
						<Link className="nav-link" to="/" name="Home" onClick={handleOnClick} >
						Inicio
						</Link>
						
					</li>
					<li className="nav-item">
					<Link className="nav-link" to="/#AboutUs" name="AboutUs" onClick={handleOnClick}> 
					{/* <a className="nav-link" href="#AboutUs"> */}
						Sobre Nosotros
						{/* </a> */}
					</Link>
					</li>
					<li className="nav-item">
					<Link className="nav-link" to="/#Novedades" name="Novedades" onClick={handleOnClick}>
					Novedades
					</Link>
					</li>
					{/* <li className="nav-item">
					<Link className="nav-link" to="/Cronograma" name="Cronograma" >Cronograma</Link>
					</li> */}
				
					<li className="nav-item">
					<Link className="nav-link" to="/Galeria" name="Galeria" >Galería</Link>
					</li>
					<li className="nav-item">
					<Link className="nav-link" to="/Contactenos" name="Contactenos" >Contáctenos</Link>
					</li>
				</ul>
				
				</div>
			</div>
			</nav>

	
	)
}

export default NavBar
