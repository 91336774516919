import '../App.css';
import './EventoDetail.css';

import card1 from "../assets/images/card1.jpg"

import Logo1 from "../assets/logosOficiales/logo_UTD-curvas-negativo2.png"
import Logo2 from "../assets/logosOficiales/Min-Desarrollo-Productivo_color-sin-fondo.png"

import Calendario from "../assets/icons/Calendario.jpg"
import Online from "../assets/icons/Online.jpg"
import Ubicacion from "../assets/icons/Ubicacion.jpg"
import Enlace from "../assets/icons/Enlace.jpg"


import React from 'react';
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom'


const EventoDetail = (props) => {

	const location = useLocation()
	 const { from } = location.state == null ? {} : location.state;

	

	const handleLoad = (event) => {
		window.scrollTo(0, 0)
	}

	// React.useEffect(() => {
	// 	console.log(props.detalle)
	// },[props.detalle])
	// 	console.log(props.detalle)

	return(
	<div className="container-fluid  p-2 "  id='AboutContainer' onLoad={handleLoad}>
			<div className='bodytext m-5 px-2 py-4 text-start'>  
				<div className='title mt-5 mb-2 m pt-3' id="Title">
					<h2> 
					{from.titulo}
					</h2>
				</div>
				
				<div className='subtitle my-3' id="Subtitle">
					<h4> 
					{from.subtitulo}
 
					</h4>
				</div>

				<div className="row g-0">
					<div className="col-md-4">
					<div className="card" style={{height: "max-content"}}>
							<img src={from.imagen} className="imgCard card-img-top" alt="..."/>
							{/* <div className="card-body">
								<p className="card-text mb-0"><small className="text-muted">Acompañan: </small></p>
								<hr className='my-0 text-secondary'/>
									<div className="mb-0" style={{width:"200px"}} >
										<img src={Logo2} alt=""  className=" d-inline-block align-text-top my-2 mx-3" style={{height:"16px"}} id="logoMinisterio"/>

										<img src={Logo1} alt=""  className=" d-inline-block align-text-top my-2 mx-3" style={{height:"16px"}} id="logoUTD"/>
									</div>
								
								<p className="card-text  mb-0"><small className="text-muted">Organizan: </small></p>
								<hr className='my-0 text-secondary'/>
									<div className="mb-0" style={{width:"200px"}} >
										<img src={Logo2} alt=""  className=" d-inline-block align-text-top my-2 mx-3" style={{height:"16px"}} id="logoMinisterio"/>

										<img src={Logo1} alt=""  className=" d-inline-block align-text-top my-2 mx-3" style={{height:"16px"}} id="logoUTD"/>
									</div>
								</div> */}
							</div>

					</div>

					<div className="col-md-8">
				<div className='content ps-4' id="content">
				<div className="row g-0">
					<div className="col-md-3 align-middle">
						<img src={Calendario} className="icono " alt="..."/>
						<p className='descripcion'> 
						{from.fecha}
	
						</p>
					</div> 

					<div className="col-md-3 align-middle">
					<img src={Ubicacion} className="icono " alt="..."/>
						{
							from.modalidad == "Virtual"  ? <a href={from.lugar}> Enlace de Videoconferencia </a> : 
						<>
						
						<p className='descripcion'> 
						{from.lugar}
	
						</p>
						</>
						}
					</div> 

					<div className="col-md-3 align-middle">
					{
							from.inscripcion != "" ? <img src={Enlace} className="icono " alt="..."/> : <></>
						}
{/* 
						<img src={Enlace} className="icono " alt="..."/> */}
						<p className='descripcion'> 
						{
							from.inscripcion != "" ? <a href={from.inscripcion}> Inscribirse Aquí </a> : <></>
						}
							{/* <a href={from.inscripcion}> Inscribirse Aquí </a> */}
						</p>
					</div> 

					<div className="col-md-3 align-middle">
					{
							from.inscripcion != "" ? <img src={Online} className="icono " alt="..."/>: <></>
						}
{/* 
						<img src={Enlace} className="icono " alt="..."/> */}
						<p className='descripcion'> 
						{
							from.inscripcion != "" ? <a> Modalidad {from.modalidad} </a> : <></>
						}
							{/* <a href={from.inscripcion}> Inscribirse Aquí </a> */}
						</p>
						
						
						<p className='descripcion'> 
						
						</p>
					</div> 

				</div>
					<div className='list mt-4  ps-3 ' id="list">
						<ul type="disc"> 
						
						{from.detalles}
						{/* <li className='pb-2'>
							<h5>
							Nullam non enim condimentum, tincidunt lectus interdum.
							</h5>
							<p className='longtext'>
							Nunc sit amet lectus sed mauris tempor maximus. Quisque a erat urna. Aliquam et magna ut odio faucibus dictum id ornare elit. Vestibulum nisl erat, fringilla in convallis ac, condimentum ac nibh. Phasellus rutrum et ex a laoreet. Sed ut accumsan purus. Nulla facilisis vulputate urna vel suscipit. Vivamus quis gravida sem. Vivamus in mi vestibulum, auctor ligula sit amet, lacinia sapien
							</p>
						</li>

						<li>
							<h5>
							Nullam non enim condimentum, tincidunt lectus interdum.
							</h5>
							<p className='longtext' >
							Nunc sit amet lectus sed mauris tempor maximus. Quisque a erat urna. Aliquam et magna ut odio faucibus dictum id ornare elit. Vestibulum nisl erat, fringilla in convallis ac, condimentum ac nibh. Phasellus rutrum et ex a laoreet. Sed ut accumsan purus. Nulla facilisis vulputate urna vel suscipit. Vivamus quis gravida sem. Vivamus in mi vestibulum, auctor ligula sit amet, lacinia sapien
							</p>
						</li> */}

					
					
						
						</ul> 

					</div>
				</div>
				</div>
				</div>
				</div>


	</div>
	)

}

export default EventoDetail
