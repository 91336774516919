import './Novedades.css';
import '../App.css';

import Card from './Card';

const NovedadesDetail = () => {
const handleLoad = (event) => {
	window.scrollTo(0, 0)
}

	const Row = () => {
		return(
			<div class="row row-cols-1 row-cols-md-3 g-2">
							<div class="col">
									<Card />
							</div>

							<div class="col">
							<Card />
							</div>

							<div class="col">
							<Card />
							</div>

							</div>
		)
	}


	return(
		<section className="page-section d-flex  flex-grow-1" id="NovedadesDetail" onLoad={handleLoad}>

	<div className="container-fluid  p-2 "  id='NovedadesContainer'>
			<div className='bodytext m-5 px-2 py-4 text-start'>
					<div className='d-flex mt-4'> 
						<div className='flex-grow-1 '>
							<div className='title my-2 pt-3' id="Title">
								<h2> 
								Novedades
								</h2>

							</div>
						</div>
					
					</div>  

					<div className='subtitle my-4' id="Subtitle">
						<h4> 
						Enterate de Todos los talleres, eventos y capacitaciones aquí 
	
						</h4>
					</div>

					<div className='content ' id="content">
					
						<Row />

						<Row />

					</div>
				</div>
	</div>
	</section>
	)

}

export default NovedadesDetail
