import '../App.css';

const Home = () => {
	return(
	<div className="container-fluid p-0 "  id='MainContainer'>
				<div className='box '>
				
						<div className=' position-absolute top-50 start-0 translate-middle-y px-4 mx-4'>
							<div className='row p-2  '>
							<h1>Unidad de <br></br> 
							Transformación <br></br>
							Digital</h1>
							</div>
							<div className='row p-2 '>
							<hr></hr>
							</div>
							<div className='row p-2 '>
							<h3>Sede Santiago del Estero</h3>
							</div>
							</div>
				</div>
				
	</div>
	)

}

export default Home
