import './Novedades.css';
import '../App.css';

import FotoCard from './FotoCard';

import Foto1 from "../assets/fotos/jornadas de capacitacion.jpg"
import Foto2 from "../assets/fotos/reuniones con uvt de otras universidades.jpg"
import Foto3 from "../assets/fotos/reunion equipo de trabajo.jpg"
import Foto4 from "../assets/fotos/con empresarios jovenes.jpg"
import Foto5 from "../assets/fotos/reuniones con referentes de proyectos .jpg"
import Foto6 from "../assets/fotos/unse y emprendedorismo.jpg"
import Foto7 from "../assets/fotos/unse_rally de innovacion.jpg"
import Foto8 from "../assets/fotos/participacion mujeres emprendedoras.jpg"

import Foto9 from "../assets/novedades/GRACIAS TOTALES Equipo UTD UVT-UNSE.png"
import Foto10 from "../assets/fotos/capacitadores.jpeg"
import Foto11 from "../assets/fotos/equipo1.jpeg"
import Foto12 from "../assets/fotos/disertacion.jpeg"

import Foto13 from "../assets/fotos/Foto1.jpeg"
import Foto14 from "../assets/fotos/Foto2.jpeg"
import Foto15 from "../assets/fotos/Foto3.jpeg"



const Galeria = () => {
const handleLoad = (event) => {
	window.scrollTo(0, 0)
}

	const Row = () => {
		return(
			<div>
				<div class="row mb-4 g-4 ">
							<div class="col">
									<FotoCard foto={Foto13} referencia={"Foto9"} titulo={"Reunión UISDE"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto14} referencia={"Foto10"} titulo={"Acompañamiento UISDE"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto15} referencia={"Foto11"} titulo={"Equipo Técnico"}/>
							</div>
							
				</div>


				<div class="row mb-4 g-4 ">
							<div class="col">
									<FotoCard foto={Foto9} referencia={"Foto9"} titulo={"Primer Taller UTD UVT UNSE"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto10} referencia={"Foto10"} titulo={"Equipo Capacitador y Expertos"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto11} referencia={"Foto11"} titulo={"Equipo Técnico"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto12} referencia={"Foto12"} titulo={"Disertación sobre Sensibilización Temática Industria 4.0"}/>
							</div>

				</div>

			<div class="row mb-4 g-4 ">
							<div class="col">
									<FotoCard foto={Foto1} referencia={"Foto1"} titulo={"Jornadas de Capacitación"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto2} referencia={"Foto2"} titulo={"Reuniones con UVT de otras universidades"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto3} referencia={"Foto3"} titulo={"Reunión de equipo de Trabajo"}/>
							</div>

							<div class="col">
							<FotoCard foto={Foto4} referencia={"Foto4"} titulo={"Con Empresarios Jóvenes"}/>
							</div>

				</div>

				<div class="row mb-4 g-4 ">
				<div class="col">
						<FotoCard foto={Foto5} referencia={"Foto5"} titulo={"Reuniones con Referentes de Proyectos"}/>
				</div>

				<div class="col">
				<FotoCard foto={Foto6} referencia={"Foto6"} titulo={"UNSE y Emprendedorismo"}/>
				</div>

				<div class="col">
				<FotoCard foto={Foto7} referencia={"Foto7"} titulo={"UNSE Rally de Innovación"}/>
				</div>

				<div class="col">
				<FotoCard foto={Foto8} referencia={"Foto8"} titulo={"Participación de Mujeres Emprendedoras"}/>
				</div>

</div>
</div>
		)
	}


	return(
		<section className="page-section d-flex  flex-grow-1" id="NovedadesDetail" onLoad={handleLoad}>

	<div className="container-fluid  p-2 "  id='NovedadesContainer'>
			<div className='bodytext m-5 px-2 py-4 text-start'>
					<div className='d-flex mt-4'> 
						<div className='flex-grow-1 '>
							<div className='title mb-3 mt-2 pt-3' id="Title">
								<h2> 
								Galería de Fotos
								</h2>

							</div>
						</div>
					
					</div>  
					

					<div className='content g-2 ' id="content">
					
						<Row />

						

					</div>
				</div>
	</div>
	</section>
	)

}

export default Galeria
