
import './App.css';

import NavBar from './components/NavBar/NavBar';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Novedades from './components/Novedades';

import NovedadesDetail from './components/NovedadesDetail';
import { useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
	Switch,
    Link
} from 'react-router-dom';
import { useEffect } from 'react';
import React from 'react';

import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

import EventoDetail from './components/EventoDetail';
import Cronograma from './components/Cronograma';
import Galeria from './components/Galeria';
import Contactenos from './components/Contactenos';



function App() {

	

const [Paging, setPaging] = useState(0);



const handleClick = (status) => {
	setPaging(status)
}

const [goTo, setGoTo] = useState("Home");
;


const handleGoTo = (goTo) => {

	setGoTo(goTo);
}




const HighContainer = (props) => {


	return (

<div className='HighContainer' >
					<section   className="page-section" id="Home">
						<Home/>
					</section>

					<section  className="page-section d-flex  flex-grow-1" id="AboutUs">
						<AboutUs />
					</section>

					<hr className='text-secondary'></hr>
					<section  className="page-section  d-flex  flex-grow-1" id="Novedades">
					 <Novedades status={Paging} onStatusChange={handleClick}/> 

					</section>

					

			</div>
	)
}



  return (
    <div className="App">
		<Router>
			<NavBar goTo={goTo} onGoTo={handleGoTo}/>
			
			

			<Routes>
                 <Route exact path='/' element={< HighContainer goTo="Home" />}></Route>
				 <Route  path='/#AboutUs' element={< HighContainer goTo="AboutUs"  />} ></Route>
				 <Route exact path='/NovedadesDetail' element={< NovedadesDetail />}></Route>
				 <Route exact path='/EventoDetail' element={< EventoDetail />}></Route>
				 {/* <Route exact path='/Cronograma' element={< Cronograma />}></Route> */}
				 <Route exact path='/Galeria' element={< Galeria />}></Route>
				 <Route exact path='/Contactenos' element={< Contactenos />}></Route>
          </Routes>

		  <footer >
					<div className="footer container-fluid">
						<div className="footer-text p-4">
						<div className="row g-0">
								<div className="col-md-4 align-middle">
											<p>Teléfono: </p>
											<p><a className="footer-link"  href="tel:+543854509500">
												<LocalPhoneIcon className="me-3" />
												(+54)-385-4509500 (INT 1065)</a></p>
										</div>

										<div className="col-md-4 align-middle">
											<p>Correo Electrónico: </p>
											<p><a className="footer-link" href="mailto:utduvtunse@gmail.com">
												<MailIcon className="me-3" />
												utduvtunse@gmail.com</a></p>
										</div>

										<div className="col-md-4 align-middle">
											<p>Ubicación: </p>
											<p><a className="footer-link" target="_blank" href="https://www.google.com.ar/maps/place/UNSE%2FUniversidad+Nacional+de+Santiago+del+Estero/@-27.8157005,-64.2814645,13z/data=!4m9!1m2!2m1!1sunse+!3m5!1s0x943b4df913f0ac29:0x6d7da993ed25f896!8m2!3d-27.8015324!4d-64.2512233!15sCgR1bnNlkgERcHVibGljX3VuaXZlcnNpdHk">
												<LocationOnIcon className="me-3" />
												UNSE - Av. Belgrano Sur 1912 </a></p>
										</div>
						</div>

						<div className="row g-0">
								<div className="col-md-4 align-middle">
											<p>Instagram: </p>
											<p><a className="footer-link" target="_blank" href="https://www.instagram.com/utduvtunse/">
												<InstagramIcon className="me-3" />
												utduvtunse</a></p>
										</div>

										<div className="col-md-4 align-middle">
											<p>Facebook: </p>
											<p><a className="footer-link" target="_blank" href="https://www.facebook.com/Uvt-Utd-Unse-102628975727239 ">
												<FacebookIcon className="me-3" />
												Uvt Utd Unse</a></p>
										</div>

										<div className="col-md-4 align-middle">
											<p>Twitter: </p>
											<p><a className="footer-link" target="_blank" href="https://twitter.com/UdtUvt">
												<TwitterIcon className="me-3" />
												@UdtUvt </a></p>
										</div>
						</div>

						</div>
					
					</div>
					</footer>
		</Router>

	
    </div>
  );
}

export default App;
