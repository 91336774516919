import '../App.css';
import './Novedades.css';
import './Galeria.css';

import card1 from "../assets/images/card1.jpg"
import card2 from "../assets/images/card2.jpg"

import Logo1 from "../assets/logosOficiales/logo_UTD-curvas-negativo2.png"
import Logo2 from "../assets/logosOficiales/Min-Desarrollo-Productivo_color-sin-fondo.png"

import { Link } from 'react-router-dom'



import EventoDetail from './EventoDetail';
import { useState } from 'react';
import React from 'react';
import { useEffect } from 'react';
const FotoCard = (props) => {

	
	const [foto, setFoto] =useState(props.foto);

	const [referencia, setReferencia] =useState(props.referencia);

	

	
	const handleLoad  = (event) => {
		setFoto(props.foto);
		setReferencia(props.referencia);
	}

	const ModalFoto = (props) => {
		const [foto, setFoto] =useState(props.foto)
		const [referencia, setReferencia] =useState(props.referencia);

		const handleFocus  = (event) => {
			setFoto(props.foto);
			setReferencia(props.referencia);
			
		}

		React.useEffect(()=>{
			
		},[foto])
		
		return (
			// <!-- Modal -->
			<div class="modal fade" id={referencia} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onFocus={handleFocus}>
			  <div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
				  <div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">{props.titulo}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body mx-auto">
				  <img src={foto} className="img-galeria-modal card-img-top" alt="..." />
				  </div>
				  <div class="modal-footer">
				  <p className="card-text">{props.titulo}</p>
					
				  </div>
				</div>
			  </div>
			</div>
		)
	}

	return(
			<>
			<ModalFoto foto={foto} referencia={referencia} titulo={props.titulo}/>
				<div className="foto-card card shadow-sm mb-3" data-bs-toggle="modal" data-bs-target={"#"+referencia} onClick={handleLoad} style={{width: "18rem"}}>
					<img src={foto} className="img-galeria card-img-top" alt="..." onLoad={handleLoad}/>
					<div className="card-body">
						<p className="card-text">{props.titulo}</p>
					</div>
		</div>
		</>

	)

}

export default FotoCard
