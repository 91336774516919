import '../App.css';
import './Novedades.css';

import card1 from "../assets/images/card1.jpg"
import card2 from "../assets/images/card2.jpg"

import Logo1 from "../assets/logosOficiales/logo_UTD-curvas-negativo2.png"
import Logo2 from "../assets/logosOficiales/Min-Desarrollo-Productivo_color-sin-fondo.png"

import { Link } from 'react-router-dom'

import EventoDetail from './EventoDetail';

const Card = (props) => {

	const handleClick  = (event) => {
		
		return ( <EventoDetail detalle={props.detalle}  />)
	}

	
	return(
		<Link className="sinUnderline" to="/EventoDetail" state={{from: props.detalle}}>
		<div className="event card shadow-sm mb-3" style={{maxWidth: "540px"}} >
		<div className="row g-0">
			<div className="col-md-6">
			<img src={props.detalle.imagen} className="img-fluid rounded-start" alt="" />
			</div>
			<div className="col-md-6">
		
			<div className="card-body h-100">
				<span class="badge  mb-3"> {props.detalle.tipoNovedad} </span>
				<h2 className="card-title">{props.detalle.titulo}</h2>
				<h4 class="card-subtitle mb-2 text-muted">{props.detalle.subtitulo}</h4>
				<p class="card-text">{props.detalle.descripcion}</p>
				<p className="card-text"><small className="text-muted"></small></p>
				<hr className='mb-0 text-secondary'/>
				<div className="mb-0" style={{width:"200px"}} >
				<img src={Logo2} alt=""  className=" d-inline-block align-text-top my-2 mx-3" style={{height:"16px"}} id="logoMinisterio"/>

				<img src={Logo1} alt=""  className=" d-inline-block align-text-top my-2 mx-3" style={{height:"16px"}} id="logoUTD"/>
				</div>
			</div>
				
			</div>
		</div>

		
</div>
</Link>
	)

}

export default Card
