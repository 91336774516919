import './Novedades.css';
import '../App.css';
import './Contactenos.css';

import emailjs from "emailjs-com"
import { useState } from 'react';
import Card from './Card';

const Contactenos = () => {
const handleLoad = (event) => {
	window.scrollTo(0, 0)
}

/// EMAIL:
const frmContact = { userEmail:"", userName:"", userCompany:"", userNumber:"",emailConsult:"" };
   const [contact,setContact] = useState(frmContact);
   const [showMessage, setShowMessage] = useState(false);
   const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };
   const handleSubmit = (e) =>{
	    e.preventDefault();
	   
		emailjs.send("service_31r6lsq","template_f57gc08", contact, "YsBizN2S10H3qbk-r")
		.then((response) => {
				   console.log("SUCCESS!", response.status, response.text);
				   setContact(frmContact);
				   setShowMessage(true);
		}, (err) => {
				   console.log("FAILED...", err);
		});
   }

	return(
		<section className="page-section d-flex  flex-grow-1" id="NovedadesDetail" onLoad={handleLoad}>

	<div className="container-fluid  p-2 "  id='NovedadesContainer'>
			<div className='bodytext m-5 px-2 py-4 text-start'>
					<div className='d-flex mt-4'> 
						<div className='flex-grow-1 '>
							<div className='title my-2 pt-3' id="Title">
								<h2> 
								Contacto
								</h2>

							</div>
						</div>
					
					</div>  

					<div className='subtitle my-4' id="Subtitle">
						<h4> 
						Mandanos tu consulta completando este formulario:
	
						</h4>
					</div>

					<div className='content mt-2' id="content">
					
							<div className='row my-2'>

								<div className="col-md-6 mb-3">
								<label for="exampleFormControlInput1" className="form-label">Nombre Completo:</label>
								<input type="text" class="form-control"  value={contact.userName}  name="userName" onChange={handleChange} id="exampleFormControlInput1" placeholder="Nombre Nombre Apellido"/>
								</div>

								<div className=" col-md-6 mb-3">
								<label for="exampleFormControlInput2" className="form-label">Nombre de la Empresa:</label>
								<input type="text" class="form-control" value={contact.userCompany}  name="userCompany" onChange={handleChange} id="exampleFormControlInput2" placeholder="Companía S.A"/>
							</div>

					</div>

					<div className='row my-2'>

							<div className="col-md-6  mb-3">
							<label for="exampleFormControlInput3" className="form-label">Teléfono / Celular:</label>
							<input type="text" value={contact.userNumber}  name="userNumber" onChange={handleChange}   className="form-control" id="exampleFormControlInput3" placeholder="123456"/>
							</div>

							<div className=" col-md-6  mb-3">
							<label for="exampleFormControlInput4" className="form-label">Correo Electrónico:</label>
							<input type="email" className="form-control"  value={contact.userEmail}  name="userEmail" onChange={handleChange} id="exampleFormControlInput4" placeholder="nombre@tucorreo.com"/>
							</div>
					</div>		
					
					<div className='row my-2'>

					<div className="mb-3">
					<label for="exampleFormControlTextarea1" className="form-label">Consulta / Mensaje:</label>
					<textarea className="form-control" value={contact.emailConsult}  name="emailConsult" onChange={handleChange} id="exampleFormControlTextarea1" rows="6"></textarea>
					</div></div>


		<button className="btn btn-primary" onClick={handleSubmit}>Enviar</button>
					
				
				
		</div>
		</div>
		</div>
	</section>
	)

}

export default Contactenos
