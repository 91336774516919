import '../App.css';



const AboutUs = () => {
	return(
	<div className="container-fluid  p-2 "  id='AboutContainer'>
			<div className='bodytext m-5 px-2 py-4 text-start'>  
				<div className='title my-5 pt-3' id="Title">
					<h2> 
					UNIDAD DE VINCULACION TECNOLOGIA - UVT UNSE 
					</h2>
				</div>
				
				<div className='subtitle my-4' id="Subtitle">
					<h4> 
					"Donde las Ideas se Fortalecen"
					</h4>
				</div>

				<div className='content ' id="content">
					<p className='longtext'> 
					La UVT-UNSE fue Creada por Resolución Nº 173/97 de la Secretaría de Ciencia y Tecnología del Ministerio de Cultura  y Educación, el 4 de Septiembre de 1997 en los términos de la Ley 23.877 - Promoción y Fomento de la Innovación Tecnológica. 

 
					</p>

					<div className='list my-3 ps-3 ' id="list">
						<ul type="disc">
						<li className='pb-2'>
							<h5>
							NUESTRA VISIÓN: 
							</h5>
							<p className='longtext'>
							Identificar, seleccionar, formular, gestionar y administar: proyectos de inversión, investigación, desarrollo e innovación, transmisión de tecnología y asistencia técnica.

							</p>
						</li>

						<li>
							<h5>
							NUESTRA MISIÓN: 
							</h5>
							<p className='longtext' >
							Es poner a disposición de los sectores productivos y sociales los recursos científicos y tecnológicos generados en el ámbito Universitario y contribuir al desarrollo y fortalecimiento de emprendimientos en su etapa inicial para favorecer su ingreso y permanencia en el ecosistema emprendedor.
							</p>
						</li>

						
						<li>
							<h5>
							Los SERVICIOS que ofrece la UVT UNSE son:
							</h5>
							<p className='longtext' >
							•	Difusión de la oferta tecnológica de la Universidad Nacional de Santiago del Estero. <br></br>
							•	Información Líneas de Financiamiento de Créditos y Subsidios, existentes a nivel Provincial, Nacional e Internacional.<br></br>
							•	Identificar, Seleccionar y Formular Proyectos de Investigación, Desarrollo e Inversión.<br></br>
							•	Asesoramiento en Propiedad Intelectual.<br></br>
							•	Administración de Proyectos de Investigación, Desarrollo e Inversión.<br></br>
							•	Asesorar y Difundir las Leyes de Promoción Económica y Parques.<br></br>
							•	Asistir con transferencias de tecnologías a las Unidades Socioeconómicas que así lo requieran.<br></br>
							•	Implementación de Cursos de Capacitación orientados a la satisfacción de las necesidades del sector socio-económico. <br></br>
							•	Gestión y fortalecimiento del proceso de articulación con el sector socio-productivo.<br></br>
							•	Organización de Encuentros Empresariales y Rondas de Negocios.<br></br>

							</p>
						</li>
						
						</ul> 

					</div>
				</div>
				</div>
	</div>
	)

}

export default AboutUs
