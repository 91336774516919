import './Novedades.css';
import '../App.css';

import Card from './Card';



import imagen1 from "../assets/novedades/Taller Introduccion 22-3-22.jpeg"
import imagen2 from "../assets/novedades/FLYER de taller de sensibilizacion- Catamarca.jpg"
import imagen3 from "../assets/novedades/Programa Taller de Sensibilización - Catamarca.jpg"
import imagen4 from "../assets/novedades/TallerUisde.png"
import imagen5 from "../assets/novedades/TallerBigData.jpeg"
import imagen6 from "../assets/novedades/SeminarioIA.jpeg"

import { useState } from 'react';

const Novedades = (props) => { 
	const [detalleCard6, setDetalleCard6 ] = useState ({
		titulo:"Seminario Virutal Gratuito",
		tipoNovedad:"SEMINARIO" ,
		imagen: imagen6,
		subtitulo:"Seminario de Inteligencia Artificial en las PYMES" ,
		descripcion:"03/08/2022 - VIRTUAL" ,
		fecha: "03/08 - 9:00hs",
		modalidad: "Virtual",
		lugar: "https://bit.ly/3PYTmHN",
		inscripcion: "https://forms.gle/yWcXQRbdk44PTYp9A",
		detalles: "\t Estimado/a, \n \t \n \t 	El próximo Miércoles 3 de Agosto se llevará a cabo el Seminario Virtual “Inteligencia Artificial” a las 9hs en Webex. \n \t 		Aplicación de la Inteligencia Artificial en las PyMES: derribando mitos. Es libre y gratuita!  \n \t \n \t  	🔹Para inscribirte ingresá a https://forms.gle/yWcXQRbdk44PTYp9A1	\n \t 	🔹Enlace Plataforma Virtual https://bit.ly/3PYTmHN	\n \t 	🔹Para más info escribinos a utduvtunse@gmail.com \n \t 		Por cualquier duda o consulta llamanos: 3854044599 / 3854114850 \n \t 		¡¡Te esperamos!!"
	})


	const [detalleCard5, setDetalleCard5 ] = useState ({
		titulo:"Tercer Taller",
		tipoNovedad:"TALLER" ,
		imagen: imagen5,
		subtitulo:"Taller Sensibilización en la temática 4.0 - SDE" ,
		descripcion:"14/07/2022 - Presencial" ,
		fecha: "14/07 - 8:30hs",
		modalidad: "Presencial",
		lugar: "UISDE - Av. 2 de Septiembre, Parque Industrial, La Banda, Santiago del Estero",
		inscripcion: "https://goo.su/K6ARblJ",
		detalles: "\t Tercera edición del Taller de Sensibilización sobre las oportunidades y el impacto de la Transformación Digital y procesos de cambio en las distintas dimensiones de la estructura de una empresa. Cuesitonario INDTech. \n \t Contenido: BIG DATA - INTERNET DE LAS COSAS - INTELIGENCIA ARTIFICIAL - TRAZABILIDAD - SENSORIZACIÓN - TELEDETECCIÓN. \n \t Disertante: Lic. Mariano Lescano - Moderadora: Esp. Lic. Teresa Fischer.  "
	})


	const [detalleCard4, setDetalleCard4 ] = useState ({
		titulo:"Gracias Uisde",
		tipoNovedad:"EVENTO" ,
		imagen: imagen4,
		subtitulo:"La Uisde acompañó a industriales beneficiarios del Plan de Transformación Digital" ,
		descripcion:"23/06/2022" ,
		fecha: "23/06/2022 ",
		modalidad: "-",
		lugar: "Unión Industrial",
		inscripcion: "-",
		detalles: "\t  En el marco del PNUD (Programa de Naciones Unidas para el Desarrollo) ARG/20/007 “Proyecto para la promoción de la competitividad, innovación y desarrollo productivo de las MiPymes”, se llevó a cabo, en las instalaciones de la Unión Industrial de Santiago del Estero (Uisde), ubicadas en el Parque Industrial La Isla, La Banda, una reunión informativa para dar a conocer a las primeras empresas seleccionadas, que fueron beneficiadas del Plan de Transformación Digital (PTD), el esquema de proyecto, en su contenido y desarrollo.\n \t La UNSE (Universidad Nacional de Santiago del Estero) puso en marcha, el pasado 22 de marzo de este año, el Proyecto en Transformación Digital – Industria 4-0. Este proyecto cuenta con el acompañamiento de la Uisde, quienes abordaron la transformación digital para las organizaciones desde la valorización de los recursos humanos para generar resultados desde lo tecnológico y dependiendo el diagnóstico de las industrias locales para lograr resultados favorables. \n \t Profesionales de las Unidades de Transformación Digital propusieron a los integrantes de industrias santiagueñas la posibilidad de trabajo en conjunto para la evolución de las organizaciones y en el plano comunicación desde lo tecnológico y el avance de la producción. \n \t \n \t Ver Nota Completa en: https://www.nuevodiarioweb.com.ar/noticias/2022/06/23/348032-la-uisde-acompano-a-industriales-beneficiarios-del-plan-de-transformacion-digital"
	})

	const [detalleCard3, setDetalleCard3 ] = useState ({
		titulo:"Programa - Segundo Taller",
		tipoNovedad:"PROGRAMA" ,
		imagen: imagen3,
		subtitulo:"PROGRAMA: Sensibilización en la temática 4.0 - Edición Catamarca" ,
		descripcion:"27/05/22 - Presencial" ,
		fecha: "27/05 - 8:30hs",
		modalidad: "Presencial, Libre y Gratuita",
		lugar: "Hotel Casino Catamarca (Esquiú 151, K4702 San Fernando del Valle de Catamarca)",
		inscripcion: "https://bit.ly/3N4aLx7",
		detalles: "\t  La interacción Universidad–Industria-Gobierno es clave para mejorar las condiciones de innovaciones en una sociedad basada en el conocimiento que transita la cuarta revolución industrial; es por ello que te invitamos al  Taller de Sensibilización en la temática de Industria 4.0 que organiza el Ministerio de Desarrollo Productivo de la Nación, SEPyME  y la UTD de la Universidad Nacional de Santiago del Estero, con el acompañamiento de la Facultad de Tecnología y Ciencias Aplicadas de la Universidad Nacional de Catamarca (UNCA), El Ministerio de Industria Comercio y Empleo de Catamarca y la UICA (Unión Industrial de Catamarca).\n \t El objetivo es dar a conocer las oportunidades y el impacto de la transformación digital y procesos de cambio en las distintas dimensiones de la estructura de la empresa."
	})

	const [detalleCard2, setDetalleCard2 ] = useState ({
		titulo:"Segundo Taller",
		tipoNovedad:"TALLER" ,
		imagen: imagen2,
		subtitulo:"Sensibilización en la temática 4.0 - Edición Catamarca" ,
		descripcion:"27/05/22 - Presencial" ,
		fecha: "27/05 - 8:30hs",
		modalidad: "Presencial, Libre y Gratuita",
		lugar: "Hotel Casino Catamarca (Esquiú 151, K4702 San Fernando del Valle de Catamarca)",
		inscripcion: "https://bit.ly/3N4aLx7",
		detalles: "\t  La interacción Universidad–Industria-Gobierno es clave para mejorar las condiciones de innovaciones en una sociedad basada en el conocimiento que transita la cuarta revolución industrial; es por ello que te invitamos al  Taller de Sensibilización en la temática de Industria 4.0 que organiza el Ministerio de Desarrollo Productivo de la Nación, SEPyME  y la UTD de la Universidad Nacional de Santiago del Estero, con el acompañamiento de la Facultad de Tecnología y Ciencias Aplicadas de la Universidad Nacional de Catamarca (UNCA), El Ministerio de Industria Comercio y Empleo de Catamarca y la UICA (Unión Industrial de Catamarca).\n \t El objetivo es dar a conocer las oportunidades y el impacto de la transformación digital y procesos de cambio en las distintas dimensiones de la estructura de la empresa."
	})

	const [detalleCard1, setDetalleCard1 ] = useState ({
		titulo:"Primer Taller",
		tipoNovedad:"TALLER" ,
		imagen: imagen1,
		subtitulo:"Sensibilización en la temática 4.0" ,
		descripcion:"21/03/22 - Presencial" ,
		fecha: "21/03 - 8:30hs a 15:00hs",
		modalidad: "Presencial",
		lugar: "UISDE - Av. 2 de Septiembre, Parque Industrial, La Banda, Santiago del Estero",
		inscripcion: "https://bit.ly/3KOLtC3",
		detalles: "Primera edición del Taller de Sensibilización en la temática de Industria 4.0 que organiza la UTD de la UNSE con el acompañamiento de la UISDE."
	})




	const Row = () => {
		return(
			<div>
					<div className="row row-cols-1 row-cols-md-3 g-2">
					<div className="col">
									<Card detalle={detalleCard6} />
							</div>
					<div className="col">
									<Card detalle={detalleCard5} />
							</div>

				<div className="col">
									<Card detalle={detalleCard4} />
							</div>
							

				
							
				
				</div>

			<div className="row row-cols-1 row-cols-md-3 g-2">
			<div className="col">
									<Card detalle={detalleCard3} />
							</div>
				
			<div className="col">
									<Card detalle={detalleCard2} />
							</div>
				<div className="col">
									<Card detalle={detalleCard1} />
							</div>

			

				</div>

			

				</div>

		)
	}

	const onClickHandle = (event) => {
		props.onStatusChange(1);
	}

	return(
	<div className="container-fluid  p-2 "  id='NovedadesContainer'>
			<div className='bodytext m-5 px-2 py-4 text-start'>
					<div className='d-flex mt-4'> 
						<div className='flex-grow-1 '>
							<div className='title my-2 pt-3' id="Title">
								<h2> 
								Novedades y Anuncios
								</h2>

							</div>
						</div>
						{/* <div className=''>
							<div className='ms-auto my-2 pt-3' id="Title">	
								<button type="button" className="btn btn-outline-primary" onClick={onClickHandle}>
								<Link to="/NovedadesDetail">
									Ver Más &gt;&gt; 
								</Link>
								</button>
								

							</div>
						</div> */}
					</div>  

					<div className='subtitle my-4' id="Subtitle">
						<h4> 
						Enterate de Todos los talleres, eventos y capacitaciones aquí 
	
						</h4>
					</div>

					<div className='content ' id="content">
					
						<Row />

						

					</div>
				</div>
	</div>
	)

}

export default Novedades
